import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { Link } from 'react-scroll'

import { Props as RoomProps } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const Filters = memo(function Filters({ rooms }: Props) {
  if (rooms.length < 1) {
    return null
  }

  const [filtersFixed, setFiltersFixed] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setFiltersFixed(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container className={filtersFixed ? 'fixed' : undefined} dial={5} row wrap>
      {uniqBy(rooms, 'title').map((item, index) => {
        const section = item.title.replace(/[^a-zA-Z]/g, '').toLocaleLowerCase()

        return (
          <Filter
            activeClass="active"
            key={index}
            offset={-139}
            spy={true}
            smooth={true}
            to={`room-${section}`}
          >
            {`${item.category} ${item.title}`}
          </Filter>
        )
      })}
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 2.5rem 3.333vw;
  position: absolute;
  top: 100vh;
  left: 0;
  z-index: 2;
  &.fixed {
    animation: filtersFadeIn 0.3s;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    position: fixed;
    top: 5rem;
  }

  @keyframes filtersFadeIn {
    0% {
      top: 0;
    }
    100% {
      top: 5rem;
    }
  }

  @media (max-width: 1199px) {
    display: none;
  }
`

const Filter = styled(Link)`
  display: flex;
  align-items: center;
  color: ${rgba(theme.colors.variants.neutralDark3, 0.6)};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.0875rem;
  line-height: 1.0625rem;
  margin-right: 3.125rem;
  transition: 0.3s ease-in-out;
  &:last-of-type {
    margin-right: 0;
    &:after {
      display: none;
    }
  }
  &.active,
  &:hover {
    color: ${({ theme }) => theme.colors.variants.secondaryDark3};
  }
  &:after {
    content: '';
    width: 0.0625rem;
    height: 1.625rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    margin-left: 3.125rem;
  }
`
